<template>
  <div class="order">
    <header>
      <div class="header h5MaxWidth clear display alignCenter">
        <div
          class="back display alignCenter justifyCenter"
          onclick="window.history.go(-1)"
        >
          <i class="iconfont icon-arrow-left"></i>
        </div>
        <h2 class="flex1">Banlance Record</h2>
        <div class="navskip" id="sideMenu"></div>
      </div>
    </header>
    <div class="banlan">
      <div class="firstbox">
        <div class="letter1">Current Balance</div>
        <div class="letter2">₹ {{ info.cashBalance }}</div>
      </div>
      <div class="or">
        <div class="inv-h-w">
          <div
            :class="tab == 0 ? 'inv-h inv-h-se' : 'inv-h'"
            @click="changeTab(0)"
          >
            My income
          </div>
          <div
            :class="tab == 1 ? 'inv-h inv-h-se' : 'inv-h'"
            @click="changeTab(1)"
          >
            Subordinate
          </div>
        </div>
        <div style="" v-if="dataList && dataList.length !== 0">
          <div class="income" v-for="(item, index) in dataList" :key="index">
            <div class="topcome">
              <div class="left">{{ item.remark }}</div>
              <div class="right">{{ item.createTime }}</div>
            </div>
            <div class="botcome">
              <div class="le">{{ item.amount }}</div>
              <div class="ri">Balance {{ item.cash }}</div>
            </div>
          </div>
        </div>
        <div class="noData" v-else>No More Data</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      info: {},
      uid: "",
      page: 1,
      type: 0,
      dataList:[]
    };
  },
  components: {},
  methods: {
    changeTab(tab){
        this.tab = tab;
        this.type = this.tab;
        this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/user/cash",
        {
          uid: this.uid,
          page: this.page,
          type: this.type,
        },
        "post"
      ).then((res) => {
        this.dataList = res.data;
      });
    },
  },
  mounted() {
    this.uid = this.getItem("uid");
    this.getAjax("api/user/cash?uid=" + this.uid, {}, "get").then((res) => {
      this.info = res.info;
    });
    this.initPage();
  },
};
</script>

<style scoped lang="less">
.lastletter {
  text-align: center;
  color: #c6c9c9;
  font-size: 13px;
  margin-top: 40px;
}
.banlan {
  height: 667px;
  padding: 5px;
  box-sizing: border-box;
}
.banlan .firstbox {
  height: 136px;
  background: #000;
  background-size: cover;
  padding-top: 13px;
  box-sizing: border-box;
  border-radius: 8px;
}
.banlan .firstbox .letter1 {
  font-size: 12px;
  font-family: PingFang SC;
  color: #fff;
  text-indent: 14px;
}
.banlan .firstbox .letter2 {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #fff;
  text-align: center;
  align-items: center;
  margin-top: 36px;
}
.banlan .or {
  margin-top: 13px;
}
.banlan .or .inv-h-w {
  height: 40px;
  line-height: 40px;
  display: flex;
  margin-bottom: 22px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  margin: 3px;
}
.banlan .or .inv-h {
  font-size: 15px;
  flex: 1;
  text-align: center;
  color: #000;
  height: 40px;
}
.banlan .or .inv-h-se {
  color: #fff;
  background-color: #dd052b;
  border-radius: 8px;
}
.banlan .or body.pages-my-banlance uni-page-body {
  background-color: #f2f2f2;
}
.banlan .or .income {
  margin-top: 5px;
}
.banlan .or .income .topcome {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  background: #dd052b;
  padding: 0 15px;
  color: #fff;
}
.banlan .or .income .topcome .left {
  font-size: 14px;
  font-family: PingFang SC;
}
.banlan .or .income .topcome .right {
  font-size: 12px;
  font-family: PingFang SC;
}
.banlan .or .income .botcome {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 20px 15px 20px 25px;
}
.banlan .or .income .botcome .le,
.banlan .or .income .botcome .ri {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #d8a418;
}
.banlan .or .income .botcome .ri {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
</style>
